import { Grid, Typography, Box, Input, Button } from "@mui/material";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import Header from "../components/Header";
import AppMenu from "../components/AppMenu";
import { SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AboutUserApp, IApplication } from "../models/Application";
import NewUserRole from "../components/NewUserRole";
import DeleteUserDialog from "../components/DeleteUserDialog";
import UserService from "../services/User";
import ApplicationService from "../services/Applications";
import SimpleSnackbar from "../components/SnackBar";
import { IRoleForApp } from "../models/Role";
import { theme } from "../style/theme";

const EditUser = () => {

	const [email, setEmail] = useState("");
	const [username, setUsername] = useState("");
	const [status, setStatus] = useState("");
	const [rolesForApps, setRolesForApps] = useState<IRoleForApp[]>([]);
	const [success, setSuccess] = useState(false);
	const [open, setOpen] = useState(false);

	const urlParameters = useParams();
	const navigate = useNavigate();

	const childToParent = (newRole: string, app: {associatedApp: string}) => {
		const newRoles = rolesForApps.map((role: IRoleForApp) => {
			if (role.app === app.associatedApp) {
				return {...role, role: newRole};
			}
			return role;
		});
		setRolesForApps(newRoles);
	};

	const getEditInfoForUser = async (idUser: string) => {
		return await UserService.getUserInfo(idUser);
	};

	const getAllApplications = async (tab: AboutUserApp[]) => {
		const apps = await ApplicationService.getAllApplications();
		apps.map((app: IApplication) => {
			let role = "no access";
			tab.some((element: AboutUserApp) => {
				if (element.name === app.name) {
					return role = element.role;
				}
			});
			setRolesForApps((roles: IRoleForApp[]) => [...roles, {
				"role": role,
				"app": app.name,
				"logo": app.logo,
				"idApp": app.idApp,
			}]);
		});
	};

	const handleChangeStatus = (status: string) => {
		status === "ACTIVE" ? setStatus("BLOCKED"):setStatus("ACTIVE");
	};
	
	const handleEmailChange = (e: { target: { value: SetStateAction<string>; }; }) => {
		setEmail(e.target.value);
	};

	const handleUsernameChange = (e: { target: { value: SetStateAction<string>; }; }) => {
		setUsername(e.target.value);
	};

	const handleSave = async () => {
		if (urlParameters.id) {
			await UserService.editUser(urlParameters.id, {email, username, status, rolesForApps})
				.then(() => {
					setSuccess(true);
					setOpen(true);
					setTimeout(() => {
						navigate("/dashboard", {replace: true});
					}, 2000);
				})
				.catch(() => {
					setOpen(true);
				});
		}
	};

	useEffect(() => {
		if (urlParameters.id) {
			getEditInfoForUser(urlParameters.id)
				.then(async (res) => {
					setEmail(res.general.email);
					setUsername(res.general.username);
					setStatus(res.general.status);
					getAllApplications(res.apps);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [setEmail, setUsername, setStatus, setRolesForApps]);

	const styles = {
		status: {
			border: "1px solid black",
			borderRadius: "10px",
			padding: "10px",
			display: "flex",
			alignItems: "center",
			marginRight: "10px",
			color: theme.palette.secondary.dark
		},
		form: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between",
			marginBottom: "50px"
		},
		formRow: {
			display: "inline-flex",
			marginBottom: "20px"
		},
		label: {
			marginRight: "20px",
			width: "20%"
		}
	};

	return (
		<>
			{
				(email && username && status) ?
					<>
						<SimpleSnackbar isOpen={open} success={success} message="User updated successfully"/>
						<Header title="Edit user" rightEdit="false" goBackTo="/dashboard"/>
						<Grid container spacing={2}>
							<Grid item xs={2} sx={{textAlign: "center", borderRight: "1px solid black"}}>
								<AppMenu apps={rolesForApps}/>
							</Grid>
							<Grid item xs={9} style={{paddingLeft: "50px"}}>
								<Box sx={styles.form}>
									<Box sx={styles.formRow}>
										<Typography sx={styles.label}>User email</Typography>
										<Input defaultValue={email} onChange={handleEmailChange}/>
									</Box>
									<Box sx={styles.formRow}>
										<Typography sx={styles.label}>Username</Typography>
										<Input defaultValue={username} onChange={handleUsernameChange}/>
									</Box>
									<Box sx={styles.formRow}>
										<Typography sx={styles.label}>Status</Typography>
										<Button sx={{...styles.status, opacity: status==="ACTIVE"?"1":"0.2"}} onClick={() => {
											handleChangeStatus(status);
										}}>
											Active <CircleRoundedIcon sx={{color: "green", marginLeft: "5px"}} fontSize="small"/>
										</Button>
										<Button style={{...styles.status, opacity: status==="ACTIVE"?"0.2":"1"}} onClick={() => {
											handleChangeStatus(status);
										}}>
											Blocked <CircleRoundedIcon sx={{color: "red", marginLeft: "5px"}} fontSize="small"/>
										</Button>
									</Box>
								</Box>
								<Box>
									<Typography variant="h5" style={{marginBottom: "25px"}}>
										Applications available to the user in the environment staging
									</Typography>
									{
										rolesForApps.length > 0 ?
											rolesForApps.map((roleForApp: IRoleForApp) => {
												return <NewUserRole 
													key={roleForApp.app} idApp={roleForApp.idApp} name={roleForApp.app}
													logo={roleForApp.logo} defaultRole={roleForApp.role} 
													childToParent={childToParent}/>;
											}) : <></>
									}
								</Box>
							</Grid>
							<Grid item xs={1}>
								<DeleteUserDialog idUser={urlParameters.id}/>
							</Grid>
							<Grid item xs={12} sx={{textAlign: "center", paddingBottom: "40px"}}>
								<Button variant="contained" onClick={handleSave}>
									Save
								</Button>
							</Grid>
						</Grid>
					</> : <></>
			}
		</>
	);
};

export default EditUser;