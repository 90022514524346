import ApiService from "./Api";

export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
  expiration: Date;
}

class Authenticate {

	async login(email: string, password: string):Promise<LoginResponse> {
		return ApiService
			.post(
				"/login",
				{ email, password }
			)
			.then(({ accessToken, refreshToken, expiration }) => ({
				accessToken,
				refreshToken,
				expiration: new Date(expiration),
			}))
			.catch((error) => {
				return error;
			});
	}

	logout() {
		ApiService.removeTokens();
		return ApiService
			.post(
				"/logout",
				{}
			);
	} 
}

export default new Authenticate();
