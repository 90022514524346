import Api from "./Api";
import { nanoid } from "nanoid";
import loginAuthorizationServer from "./loginAuthorizationServer";
import { Cookie } from "universal-cookie";
import { config } from "../config";
//import { LoginResponse } from "./Authenticate";

export const fetchSession = async (
	onLogin: (r: any) => Promise<void>,
	cookies: Cookie,
	setState: React.Dispatch<React.SetStateAction<string | null>>
) => {
	return fetch(`${process.env.REACT_APP_AUTHORIZATION_SERVER_URL}/users/sessions`, {
		method: "GET",
		credentials: "include"
	})
		.then((res) => {
			if (res && res.status===200) {
				fetch(`${process.env.REACT_APP_AUTHORIZATION_SERVER_URL}/token`, {
					headers: {
						"Accept": "application/json",
						"Content-Type": "application/json"
					},
					body: JSON.stringify({
						grant_type: "authorization_code",
						client_id: config.CLIENT_ID,
						client_secret: config.CLIENT_SECRET,
						redirect_uri: process.env.REACT_APP_LOGIN_FRONTEND_URL,
					}),
					method: "POST",
					credentials: "include"
				})
					.then(async (response) => {
						const data = await response.json();
						window.localStorage.setItem("Access", data.access_token);
						window.localStorage.setItem("Id", data.id_token);
						window.localStorage.setItem("refreshToken", data.refresh_token);
						const today = new Date();
						const tomorrow = new Date(today);
						tomorrow.setDate(tomorrow.getDate() + 1);

						const token = {
							accessToken: data.access_token,
							refreshToken: data.refresh_token,
							expiration: tomorrow
						};
						await Api.post("/auth/validate", { token });
						onLogin(token);
						window.location.href = `${process.env.REACT_APP_LOGIN_FRONTEND_URL}/dashboard`;
					})
					.catch((error) => {
						console.error("/token Post request", "Error while executing Post request", error);
					});
			} else {
				setTimeout(() => {
					if (cookies.get("state")===undefined) {
						const randomState = nanoid();
						cookies.set("state", randomState, {
							maxAge: 600
						});
						setState(randomState);
					}
					else {
						loginAuthorizationServer(cookies);
					}
				}, 1000);
			}
		})
		.catch(() => {
			throw new Error("Invalid request");
		});
};