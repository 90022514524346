//snackbar to display success/error message after submitting data
//for example when creating a user
import { IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect, Fragment } from "react";

export default function SimpleSnackbar({isOpen, success, message}: {
		isOpen: boolean;
		success: boolean;
		message: string;
	}) {
	const [open, setOpen] = useState(isOpen);

	const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	useEffect(() => {
		setOpen(isOpen);
	}, [isOpen]);

	const action = (
		<Fragment>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleClose}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</Fragment>
	);

	return (
		<Snackbar
			open={open}
			onClose={handleClose}
			message={success ? message : "An error occurred"}
			action={action}
		/>
	);
}
