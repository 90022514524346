import axios from "axios";
import { config } from "../config";

class Api {

	accessToken: string | null = null;
	refreshToken: string | null = null;
	tokenExpiration: number | null = null;

	setTokens(accessToken: string, refreshToken: string, tokenExpiration: Date) {
		this.accessToken = accessToken;
		this.refreshToken = refreshToken;
		this.tokenExpiration = tokenExpiration.getTime();
		this.storeRefreshToken(refreshToken);
	}

	removeTokens() {
		this.accessToken = null;
		this.refreshToken = null;
		this.tokenExpiration = null;
		this.clearRefreshToken();
	}

	//TODO
	//async get<T, U>(path: string, parameters?: T): Promise<U> {
	async get(path: string, parameters?: any): Promise<any> {
		if (path !== "/init-password" && path !== "/login/theme") {
			await fetch(`${process.env.REACT_APP_AUTHORIZATION_SERVER_URL}/users/sessions`, {
				method: "GET",
				credentials: "include",
				headers: {
					"Authorization": `Bearer ${this.accessToken}`
				}
			})
				.then((res) => {
					if (res.status!==200) {
						this.removeTokens();
						window.location.href = `${process.env.REACT_APP_LOGIN_FRONTEND_URL}`;
					}
				})
				.catch(() => {
					throw new Error("Cannot get session");
				});
			return await axios.get(`${process.env.REACT_APP_AUTHORIZATION_SERVER_URL}${path}`, {
				params: parameters,
				headers: {
					"Authorization": `Bearer ${this.accessToken}`
				}
			});
		}
		return await axios.get(`${process.env.REACT_APP_AUTHORIZATION_SERVER_URL}${path}`, {
			params: parameters,
		});
	}

	async delete(path: string): Promise<any> {
		return await axios.delete(`${process.env.REACT_APP_AUTHORIZATION_SERVER_URL}${path}`, {
			headers: {
				"Authorization": `Bearer ${this.accessToken}`
			}
		});
	}

	async put(path: string, body: any): Promise<any> {
		return await axios.put(`${process.env.REACT_APP_AUTHORIZATION_SERVER_URL}${path}`, body, {
			headers: {
				"Authorization": `Bearer ${this.accessToken}`
			}
		})
			.catch(async () => {
				await this.refreshTokens(window.localStorage.getItem("refreshToken") as string)
					.then(() => this.put(path, body))
					.catch(() => {
						throw new Error("Issue with refresh token");
					});
			});
	}

	async post(path: string, body: any): Promise<any> {
		if (path === "/init-password") {
			return await axios.post(`${process.env.REACT_APP_AUTHORIZATION_SERVER_URL}${path}`, body);
		}
		return await axios.post(`${process.env.REACT_APP_AUTHORIZATION_SERVER_URL}${path}`, body, {
			withCredentials: true,
			headers: {
				"Authorization": `Bearer ${this.accessToken}`
			}
		})
			.catch(async () => {
				throw new Error("Issue with refresh token");
			});
	}

	async refreshTokens(token: string) {
		const user = await axios.post(`${process.env.REACT_APP_AUTHORIZATION_SERVER_URL}/auth/user`,
			{ refreshToken: token },
			{ withCredentials: true })
			.then((response) => {
				if (response.status !== 200) {
					window.localStorage.removeItem("Access");
					window.localStorage.removeItem("refreshToken");
					window.localStorage.removeItem("Id");
					document.location.href = process.env.REACT_APP_LOGIN_FRONTEND_URL as string;
				}
			});
		return await axios.post(`${process.env.REACT_APP_AUTHORIZATION_SERVER_URL}/auth/refresh`,
			{ user,
				refreshToken: token,
				client_id: config.CLIENT_ID,
				client_secret: config.CLIENT_SECRET
			}, {
				withCredentials: true
			})
			.then((response) => {
				this.accessToken = response.data.token.access_token;
				this.refreshToken = response.data.token.refresh_token;
				this.tokenExpiration = new Date(response.data.token.expires_in).getTime();
				this.storeRefreshToken(response.data.token.refresh_token);
			})
			.catch((error) => {
				throw new Error(error);
			});
	}

	private storeRefreshToken(token: string) {
		window.localStorage.setItem("refreshToken", token);
	}

	private clearRefreshToken() {
		window.localStorage.removeItem("Access");
		window.localStorage.removeItem("Id");
		window.localStorage.removeItem("refreshToken");
	}
}

export default new Api();