//component to go to the anchor corresponding to the app in the EditUser page
import { Box, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import { IAppMenu } from "../models/Application";

export default function AppMenu(props: {apps: IAppMenu[]}) {
	return (
		<Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper"}}>
			<nav aria-label="secondary mailbox folders">
				<Typography variant="h6" sx={{fontWeight: "bold"}}>
					Applications
				</Typography>
				<List>
					{
						props.apps.length > 0 ? props.apps.map((app: IAppMenu) => {
							return <ListItem disablePadding key={app.app}>
								<ListItemButton component="a" href={`#${app.app}`}>
									<ListItemText primary={app.app} />
								</ListItemButton>
							</ListItem>;
						}) : <></>
					}
				</List>
			</nav>
		</Box>
	);
}
