import { Box, FormGroup, TextField, Typography, Button } from "@mui/material";
import Header from "../components/Header";
import NewUserRole from "../components/NewUserRole";
import { SetStateAction, useEffect, useState } from "react";
import { Application } from "../models/Application";
import { useNavigate } from "react-router-dom";
import UserService from "../services/User";
import ApplicationService from "../services/Applications";
import SimpleSnackbar from "../components/SnackBar";
import { IRoleForApp } from "../models/Role";

const NewUser = () => {

	const [email, setEmail] = useState("");
	const [username, setUsername] = useState("");
	const [rolesForApps, setRolesForApps] = useState<IRoleForApp[]>([]);
	const [success, setSuccess] = useState(false);
	const [open, setOpen] = useState(false);

	const navigate = useNavigate();

	const handleEmail = (e: { target: { value: SetStateAction<string>; }; }) => {
		setEmail(e.target.value);
	};

	const handleUsername = (e: { target: { value: SetStateAction<string>; }; }) => {
		setUsername(e.target.value);
	};

	const childToParent = (newRole: string, app: {associatedApp: string}) => {
		const newRoles = rolesForApps.map((role: IRoleForApp) => {
			if (role.app===app.associatedApp) {
				return {...role, role: newRole};
			}
			return role;
		});
		setRolesForApps(newRoles);
	};

	const createUser = async () => {
		await UserService.createUser({email, username, rolesForApps})
			.then(() => {
				setSuccess(true);
				setOpen(true);
				setTimeout(() => {
					navigate("/dashboard", {replace: true});
				}, 2000);
			})
			.catch(() => {
				setOpen(true);
			});
	};

	const getAllApplications = async () => {
		await ApplicationService.getAllApplications()
			.then((applications) => {
				applications.map((application: Application) => {
					setRolesForApps((roles: IRoleForApp[]) => [...roles, {
						"role": "no access",
						"app": application.name,
						"logo": application.logo,
						"idApp": application.idApp
					}]);
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		getAllApplications();
	}, [setRolesForApps]);

	const styles = {
		div: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			marginLeft: "20px",
			marginBottom:"20px"
		},
		label: {
			marginRight: "20px",
			width: "10%"
		},
		app: {
			display: "flex",
			flexDirection: "column",
			marginTop: "50px",
			marginLeft: "20px",
			marginBottom:"20px",
		},
		appTitle: {
			marginBottom: "20px"
		},
		createButton: {
			width: "15%",
			marginLeft: "auto",
			marginRight: "auto",
			marginBottom: "1%"
		}
	};

	return (
		<>
			<SimpleSnackbar isOpen={open} success={success} message="User created successfully"/>
			<Header title="Create user" rightEdit="false" goBackTo="/dashboard"/>
			<FormGroup sx={styles.div}>
				<Typography sx={styles.label}>Email</Typography>
				<TextField type="email" label="User email" variant="outlined" onChange={handleEmail}/>
			</FormGroup>
			<FormGroup sx={styles.div}>
				<Typography sx={styles.label}>Username</Typography>
				<TextField label="Username" variant="outlined" onChange={handleUsername}/>
			</FormGroup>
			<Box sx={styles.app}>
				<Typography variant="h6" style={styles.appTitle}>
					Applications the user will have access to in the environment staging
				</Typography>
				{
					rolesForApps.length > 0 ?
						rolesForApps.map((roleForApp: IRoleForApp) => {
							return <NewUserRole key={roleForApp.app} idApp={roleForApp.idApp} name={roleForApp.app}
								logo={roleForApp.logo} defaultRole="no access" childToParent={childToParent}/>;
						}) : <></>
				}
				<Button variant="contained" sx={styles.createButton} onClick={() => {
					createUser();
				}}>
					Create user
				</Button>
			</Box>
		</>
	);
};

export default NewUser;