//component used to filter the app in the dashboard page
//user can select only one app (options are radio buttons)
import { SelectChangeEvent } from "@mui/material/Select";
import { Box, ThemeProvider, Radio, Select, ListItemText, FormControl, MenuItem, InputLabel, OutlinedInput,
	InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";
import Filter from "../models/AppFilter";
import { theme } from "../style/theme";
import ApplicationService from "../services/Applications";
import CancelIcon from "@mui/icons-material/Cancel";

const AppFilter = ({childToParent}: Filter) => {
	const [selectedApp, setSelectedApp] = useState<string>("");
	const [applications, setApplications] = useState<string[]>([]);

	const handleChange = (event: SelectChangeEvent<typeof selectedApp>) => {
		const {
			target: { value },
		} = event;
		setSelectedApp(
			// On autofill we get a stringified value.
			value
		);
		childToParent(value.split(","));
	};

	//getting all the names of the created apps to filter on them
	const getAllApplicationsForUser = async () => {
		const appNames = await ApplicationService.getAllApplicationNamesForUser();
		setApplications(appNames);
	};

	useEffect(() => {
		getAllApplicationsForUser();
	}, [setApplications, selectedApp]);

	return (
		<ThemeProvider theme={theme}>
			<Box sx={{width: "40%"}}>
				<FormControl sx={{ width: "100%" }}>
					<InputLabel id="demo-multiple-checkbox-label">Filter by app</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label"
						id="demo-multiple-checkbox"
						value={selectedApp}
						onChange={handleChange}
						input={<OutlinedInput label="Filter app" />}
						renderValue={(selected) => selected}
						endAdornment= {
							selectedApp === "" ? <></> :
								<InputAdornment position="end" sx={{marginRight: "10%", "&:hover": {cursor: "pointer"}}}>
									<CancelIcon onClick={() => {
										setSelectedApp("");
										childToParent([]);
									}} />
								</InputAdornment>
						}
					>
						{applications.map((name) => (
							<MenuItem key={name} value={name} sx={{width: "100%"}}>
								<Radio checked={selectedApp.indexOf(name) > -1} />
								<ListItemText primary={name} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
		</ThemeProvider>
	);
};

export default AppFilter;