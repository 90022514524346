const AUTHORIZATION_SERVER_URL = process.env.REACT_APP_AUTHORIZATION_SERVER_URL;

const verifyCredentials = (
	email: string,
	password: string,
	scope: string | null,
	redirect_uri: string | null,
	state: string | null,
	client_id: string | null
) => {
	return fetch(AUTHORIZATION_SERVER_URL + "/login", {
		headers: {
			"Accept": "application/json",
			"Content-Type": "application/json"
		},
		body: JSON.stringify({
			email,
			password,
			scope,
			client_id,
			redirect_uri
		}),
		method: "POST",
		credentials: "include"
	})
		.then(async (response) => {
			if (response.status === 200) {
				const code = await response.text();
				window.location.href =
						`${redirect_uri}
						?state=${state}
						&code=${code}`;
			}
			return response.status;
		})
		.catch(() => {
			return 500;
		});
};

export default verifyCredentials;
