import { EditUser, UserInfo, UserInPage } from "../models/User";
import ApiService from "./Api";

class User {

	async me(): Promise<any> {
		return await ApiService.get("/user/me");
	}

	async deleteUser(idUser: string): Promise<number> {
		return await ApiService.delete(`/users/${idUser}`);
	}

	async getUserInfo(idUser: string): Promise<UserInfo> {
		return await ApiService.get(`/users/${idUser}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.log(error);
			});
	}

	async getUsersInPage(page: number): Promise<UserInPage> {
		return await ApiService.get("/users", {page})
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.log(error);
			});
	}

	async filterUsers(searchUsername: string, filterApps: string, page: number): Promise<UserInPage> {
		return await ApiService.get("/filter/users", {searchUsername, filterApps, page})
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.log(error);
			});
	}

	async editUser(idUser: string, body: EditUser): Promise<number[]> {
		return await ApiService.put(`/users/${idUser}`, body)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.log(error);
			});
	}

	async createUser(body: EditUser): Promise<void> {
		return await ApiService.post("/users", body);
	}
}

export default new User();