//this dialog opens when an admin tries to delete an application
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate } from "react-router-dom";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useState } from "react";
import Application from "../services/Applications";

export default function DeleteAppDialog({idApp}: {idApp: string | undefined}) {
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	//application will be deleted from authorization server's database
	const handleConfirm = async () => {
		await Application.deleteApplication(idApp as string);
		navigate("/dashboard", {replace: true});
	};

	return (
		<Box>
			<Button onClick={handleClickOpen} sx={{display: "flex", flexDirection: "column", color: "#A52A2A"}}>
				<DeleteForeverIcon fontSize="large" sx={{color: "#A52A2A"}}/>
				Delete Application
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Are you sure you want to delete this app ?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
            This action is not reversible. The app will be deleted from the database.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleConfirm} autoFocus>
            Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}
