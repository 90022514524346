//component to go to the anchor corresponding to the app in the AboutUser page
import { Box, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import { AboutUserApp } from "../models/Application";

export default function AppMenuInfo(props: {apps: AboutUserApp[]}) {
	return (
		<Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper"}}>
			<nav aria-label="secondary mailbox folders">
				<Typography variant="h6" sx={{fontWeight: "bold"}}>
					Applications
				</Typography>
				<List>
					{
						props.apps.length > 0 ? props.apps.map((app: AboutUserApp) => {
							return <ListItem disablePadding key={app.name}>
								<ListItemButton component="a" href={`#${app.name}`}>
									<ListItemText primary={app.name} />
								</ListItemButton>
							</ListItem>;
						}) : <></>
					}
				</List>
			</nav>
		</Box>
	);
}
