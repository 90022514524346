import "./index.css";
import App from "./OpenIDLogin";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutUser from "./pages/AboutUser";
import EditUser from "./pages/EditUser";
import NewApp from "./pages/NewApp";
import NewUser from "./pages/NewUser";
import EditApp from "./pages/EditApp";
import AboutApp from "./pages/AboutApp";
import SetUpPassword from "./pages/SetUpPassword";
import { UserContextProvider } from "./components/UserContext";
import User from "./models/User";
import ApiService from "./services/Api";
import UserService from "./services/User";
import { ThemeProvider } from "@mui/material";
import { theme } from "./style/theme";

const init: () => Promise<{ user: User | null }> = () => {
	const refreshToken = window.localStorage.getItem("refreshToken");
	if (!refreshToken) return Promise.resolve({ user: null });
	return ApiService
		.refreshTokens(refreshToken)
		.then(() => UserService.me())
		.then((user) => ({ user }))
		.catch(() => ({ user: null }));
};

init()
	.then((initialState) => {
		ReactDOM.render(
			<UserContextProvider initialUser={initialState.user}>
				<BrowserRouter>
					<ThemeProvider theme={theme}>
						<Routes>
							<Route path="/login" element={<Login/>} />
							<Route path="/" element={<App/>} />
							<Route path="/set-up-password/:id" element={<SetUpPassword />} />
							<Route path="/dashboard" element={<Dashboard/>} />
							<Route path="/users/:id" element={<AboutUser/>} />
							<Route path="/users/:id/edit" element={<EditUser/>} />
							<Route path="/applications" element={<AboutApp/>} />
							<Route path="/users" element={<NewUser/>} />
							<Route path="/applications/:id/edit" element={<EditApp />} />
							<Route path="/applications/new" element={<NewApp />} />
						</Routes>
					</ThemeProvider>
				</BrowserRouter>
			</UserContextProvider>,
			document.getElementById("root")
		);
	});
