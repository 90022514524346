import { TailSpin } from "react-loader-spinner";
import { Box } from "@mui/material";

const Loader = () => {
	return (
		<Box
			style={{
				width: "100%",
				height: "100",
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			}}
		>
			<TailSpin color="#2BAD60" height="100" width="100" />
		</Box>
	);
};

export default Loader;