import axios from "axios";

export const checkForSession = async (redirect_uri: string | null) => {
	try {
		const res = await axios.get(`${process.env.REACT_APP_AUTHORIZATION_SERVER_URL}/users/sessions`, {
			withCredentials: true
		});
		if (res.status === 200 && redirect_uri) {
			window.location.href = redirect_uri;
		}
	} catch {
		console.log("Error while getting session");
	}
};
