//page to display information about apps such as logo, name, redirect url
//from this page the user can choose to edit an application or create a new one
import { Grid } from "@mui/material";
import Header from "../components/Header";
import DisplayApp from "../components/DisplayApp";
import { useEffect, useState } from "react";
import { Application } from "../models/Application";
import ApplicationService from "../services/Applications";

const AboutApp = () => {

	const [applications, setApplications] = useState<Application[]>([]);

	const getAllApplications = async () => {
		const apps = await ApplicationService.getAllApplications();
		setApplications(apps);
	};

	useEffect(() => {
		getAllApplications();
	}, [setApplications]);

	return (
		<>
			<Header title="About applications" rightEdit="add" goBackTo="/dashboard"/>
			<Grid container spacing={2}>
				{
					applications.length > 0 ?
						applications.map((app: Application) => {
							return <DisplayApp key={app.idApp} idApp={app.idApp} name={app.name}
								redirect_uri={app.redirect_uri} logo={app.logo}/>;
						})
						:
						<></>
				}
			</Grid>
		</>
	);
};

export default AboutApp;