//this dialog opens when a user creates an application and want to add roles to this application
import { Button, TextField, Dialog, DialogActions, DialogContent,
	DialogContentText, DialogTitle, Box, Checkbox, FormGroup, FormControlLabel, Chip,
	MenuItem, FormControl, Select, Table, TableBody, TableCell, TableContainer,
	TableHead, TableRow, Paper } from "@mui/material";
import { SetStateAction, useState, useEffect } from "react";
import { Role, Child } from "../models/NewAppRole";

export default function NewAppRoles({childToParent}: Child) {
	const [open, setOpen] = useState(false);
	const [check, setCheck] = useState(false);
	const [roleName, setRoleName] = useState("");
	const [specificType, setSpecificType] = useState("");
	const [everyData, setEveryData] = useState<Role[]>([]);

	//name of the role
	const handleNameChange = (e: { target: { value: SetStateAction<string>; }; }) => {
		setRoleName(e.target.value);
	};

	const handleRoleChange = (e: { target: { value: SetStateAction<string>; }; }) => {
		setSpecificType(e.target.value);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setCheck(false);
		setOpen(false);
	};

	//if checked, the table with specific values appears
	const handleCheck = () => {
		setCheck(check===false);
	};

	const handleDelete = (roleToDelete: string) => {
		const updatedData = everyData.filter((item: Role) => {
			return item.name !== roleToDelete;
		});
		setEveryData(updatedData);
	};

	const handleValidate = () => {
		setEveryData([...everyData, {"name": roleName, "spec": specificType}]);
		setRoleName("");
		setSpecificType("");
		setCheck(false);
		setOpen(false);
	};
	
	useEffect(() => {
		childToParent(everyData);
	}, [everyData]);

	const styles = {
		content: {
			backgroundColor: "white",
		},
		chips: {
			marginRight: "15px",
			backgroundColor: "#F0EFE9", 
			color: "#0D7B58",
			fontWeight: "bold"
		},
		form: {
			minWidth: "100%",
		}
	};

	return (
		<>
			{
				everyData.length > 0 &&
				everyData.map((role: Role) =>
					<Chip key={role.name} label={
						role.spec ? role.name + " specific " + role.spec : role.name
					}
					onDelete={() => handleDelete(role.name)} 
					sx={styles.chips}/>
				)
			}
			<Button variant="outlined" onClick={handleClickOpen}>
				+ Add role
			</Button>
			<Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
				<DialogTitle sx={styles.content}>New role</DialogTitle>
				<DialogContent sx={styles.content}>
					<DialogContentText>
            Enter the name of the new role and specify if some specific values are required for this role
					</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="New role name"
						type="string"
						fullWidth
						variant="standard"
						value={roleName}
						onChange={handleNameChange}
					/>
					<FormGroup>
						<FormControlLabel control={<Checkbox onClick={handleCheck}/>}
							label="This role require specific values (feature in coming)" 
							disabled={true}
						/>
					</FormGroup>
					{
						check &&
							(<FormControl variant="standard" sx={styles.form}>
								<TableContainer component={Paper}>
									<Table sx={{ minWidth: 650 }} aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell align="center">Type of specific values</TableCell>
												<TableCell align="center">Name in the app</TableCell>
												<TableCell align="center">Sent name</TableCell>
												<TableCell align="center">Address to sent to</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow
												sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
											>
												<TableCell component="th" scope="row" align="center">
													<Select
														labelId="demo-simple-select-standard-label"
														id="demo-simple-select-standard"
														value={specificType}
														onChange={handleRoleChange}
													>
														<MenuItem value={"text"}>
															Free text field
														</MenuItem>
														<MenuItem value={"number"}>
															Number
														</MenuItem>
														<MenuItem value={"select"}>
															List of options
														</MenuItem>
													</Select>
												</TableCell>
												<TableCell align="center">
													<TextField />
												</TableCell>
												<TableCell align="center"><TextField /></TableCell>
												<TableCell align="center"><TextField /></TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</FormControl>)
					}
				</DialogContent>
				<DialogActions sx={styles.content}>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleValidate}>Validate</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
