//show the logo, name and role of the user in an application in AboutUser page
import { Card, CardContent, CardMedia, ThemeProvider, Typography, Box } from "@mui/material";
import { theme } from "../style/theme";
import { AboutUserApp } from "../models/Application";

const RolesForApp = (props: AboutUserApp) => {

	const styles = {
		logo: {
			borderRadius: "10px",
		},
		main: {
			display: "flex",
			padding: "10px",
			paddingBottom: "30px"
		},
		roles: {
			width: "60%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: "20px"
		},
		card: {
			display: "flex",
			flexDirection: "column" as const,
			alignItems: "center",
			justifyContent: "center",
			width: "20%",
			maxWidth: "20%"
		},
		roleName: {
			fontWeight: "bold",
			fontSize: "18px"
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<Box sx={styles.main} id={props.name}>
				<Card style={styles.card}>
					<CardMedia
						component="img"
						image={`${process.env.REACT_APP_AUTHORIZATION_SERVER_URL}/images/${props.logo}`}
						alt="app logo"
						sx={styles.logo}
					/>
					<CardContent>
						<Typography gutterBottom variant="h6" component="div">
							{props.name}
						</Typography>
					</CardContent>
				</Card>
				<Box sx={styles.roles}>
					<Typography sx={styles.roleName}>Role : {props.role}</Typography>
				</Box>
			</Box>
		</ThemeProvider>
	);
};

export default RolesForApp;