import ApiService from "./Api";
//using IApplication avoids name conflict between the interface Application and the class name
import { IApplication } from "../models/Application";
import { RoleForApp } from "../models/Role";
import { Role } from "../models/NewApp";

class Application {
	async getAllApplicationNamesForUser(): Promise<string[]> {
		//return ApiService.get<any, any>("/applications")
		//	.then((response) =>
		//		response.data.map((application: IApplication) => application.name)
		//	);
		return ApiService.get("/applications")
			.then((response) =>
				response.data.map((application: IApplication) => application.name)
			)
			.catch((error) => {
				console.log(error);
			});
	}

	async getAllApplications(): Promise<IApplication[]> {
		return ApiService.get("/applications")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.log(error);
			});
	}

	async deleteApplication(idApp: string): Promise<string> {
		return ApiService.delete(`/applications/${idApp}`);
	}

	async getRolesForApplication(idApp: string): Promise<RoleForApp[]> {
		return ApiService.get(`/applications/${idApp}/roles`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.log(error);
			});
	}

	async getApplication(idApp: string): Promise<IApplication> {
		return ApiService.get(`/applications/${idApp}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.log(error);
			});
	}

	async editApplication(idApp: string, body: {name: string, redirect: string, new_roles: Role[]}): Promise<void> {
		return ApiService.put(`/applications/${idApp}`, body);
	}

	async addNewApplication(body: FormData): Promise<any> {
		return ApiService.post("/applications", body);
	}
}

export default new Application();