import { Button, Grid, Hidden, InputAdornment, OutlinedInput, Typography } from "@mui/material";
import UserTab from "../components/UserTab";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import SearchIcon from "@mui/icons-material/Search";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import AppFilter from "../components/AppFilter";
import { DashboardUser } from "../models/Application";
import UserService from "../services/User";
import AuthenticateService from "../services/Authenticate";
import jwt_decode from "jwt-decode";

const DashboardPage = () => {

	const navigate = useNavigate();
	const [searchUsername, setSearchUsername] = useState("");
	const [childData, setChildData] = useState<string[]>([]);
	const [page, setPage] = useState(0);
	const [total, setTotal] = useState(0);
	const [newDashboardData, setNewDashboardData] = useState<DashboardUser[]>([]);
	const [userFromToken, setUserFromToken] = useState({sub: null});

	const childToParent = (childData: string[]) => {
		setChildData(childData);
	};

	const changePage = (newPage: number) => {
		setPage(newPage);
	};

	const handleSearch = (value: string) => {
		setSearchUsername(value);
	};

	const handleLogout = () => {
		AuthenticateService.logout()
			.then(() => {
				navigate("/", {replace: true});
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getUsers = async (page: number) => {
		const usersForPage = await UserService.getUsersInPage(page);
		setTotal(usersForPage.total);
		setNewDashboardData(usersForPage.users);
	};

	const filter = async () => {
		const filterApps = JSON.stringify(childData);
		const filteredUsers = await UserService.filterUsers(searchUsername, filterApps, page);
		setTotal(filteredUsers.total);
		setNewDashboardData(filteredUsers.users);
	};

	useEffect(() => {
		setUserFromToken(jwt_decode(window.localStorage.getItem("Id") as string));
		getUsers(page);
		if (searchUsername !== "" || childData.length > 0) {
			const timer = setTimeout(() => {
				filter();
			}, 500);
			return () => clearTimeout(timer);
		}
	}, [page, setNewDashboardData, setTotal, searchUsername, childData]);

	const styles = {
		logout: {
			padding: "0px",
			display: "flex",
			justifyContent: "center"
		},
		logoutButton: {
			display:"flex",
			flexDirection:"column",
			alignItems: "center",
			textAlign: "center",
			marginTop: "1%",
			color: "black",
			padding: 0
		},
		logoutText: {
			fontSize: "12px"
		},
		create: {
			display: "flex",
			justifyContent: "space-evenly",
			alignItems:"center"
		},
		search: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-evenly"
		},
		main: {
			height: "15%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			backgroundColor: "white",
			marginBottom: "20px",
		},
		createManageButton: {
			width: "45%",
			height: "100%"
		},
		table: {
			marginBottom: "20px"
		}
	};

	return (
		<><Grid container sx={styles.main}>
			<Grid item xs={1} sx={{ color: "black", textAlign: "center", padding: "5px" }}>
				Welcome {userFromToken.sub !== null ? userFromToken.sub : ""}
			</Grid>
			<Grid item xs={10} sx={{ textAlign: "center" }}>
				<Typography variant="h4">SSO admin dashboard</Typography>
			</Grid>
			<Grid item md={1} xs={1} sm={1} style={styles.logout}>
				<Button sx={styles.logoutButton} onClick={handleLogout}>
					<LogoutIcon fontSize="large" />
					<Typography sx={styles.logoutText}>Logout</Typography>
				</Button>
			</Grid>
		</Grid><Grid container spacing={2}>
			<Hidden only={"sm"}>
				<Grid item md={1} xs={1} sm={0}></Grid>
			</Hidden>
			<Grid item md={6} xs={5} sm={6} sx={styles.search}>
				<OutlinedInput name="user" placeholder="Username" onChange={(event: { target: { value: string; }; }) => {
					handleSearch(event.target.value);
				} } endAdornment={<InputAdornment position="end"><SearchIcon /></InputAdornment>} />
				<AppFilter childToParent={childToParent} />
			</Grid>
			<Grid item md={4} xs={5} sm={5} style={styles.create}>
				<Button variant="contained" onClick={() => {
					navigate("/users");
				} } sx={styles.createManageButton}>
					<PersonAddIcon />
						Create user
				</Button>
				<Button variant="contained" onClick={() => {
					navigate("/applications");
				} } sx={styles.createManageButton}>
					<AppSettingsAltIcon />
						Manage applications
				</Button>
			</Grid>
			<Grid item md={1} xs={1} sm={1}></Grid>

			<Grid item md={1} xs={1} sm={0}></Grid>
			<Grid item md={10} xs={10} sm={12} sx={styles.table}>
				<UserTab changePage={changePage} total={total} realData={newDashboardData} />
			</Grid>
			<Grid item md={1} xs={1} sm={0}></Grid>
		</Grid></>
	);
};

export default DashboardPage;