//component used to display the apps in the AboutApp page
//user can click on "edit" to edit the information of the application
import { Box, Grid, Typography, Button, CardMedia, CardContent, CardActions, Card } from "@mui/material";
import { Application } from "../models/Application";
import { useNavigate } from "react-router-dom";

export default function DisplayApp({name, redirect_uri, idApp, logo}: Application) {

	const styles = {
		card: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			width: "80%",
			height: "100%",
			margin: "10px",
		},
		logo: {
			maxWidth: "30%",
			borderRadius: "10px",
			margin: "auto"
		},
	};

	const navigate = useNavigate();

	return (
		<Grid item xs={6} md={4}>
			<Card sx={styles.card}>
				<CardMedia
					component="img"
					alt="logo"
					image={`${process.env.REACT_APP_AUTHORIZATION_SERVER_URL}/images/${logo}`}
					sx={styles.logo}
				/>
				<CardContent sx={{textAlign: "center"}}>
					<Typography gutterBottom variant="h6" component="p">
						{name}
					</Typography>
					<Box>
						Redirect url : <a href={`${redirect_uri}`}>{redirect_uri}</a>
					</Box>
				</CardContent>
				<CardActions>
					<Button size="small" onClick={() => {
						navigate(`/applications/${idApp}/edit`);
					}}>
						Edit
					</Button>
				</CardActions>
			</Card>
		</Grid>
	);
}
