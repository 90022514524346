import { useContext } from "react";
import UserContext from "../components/UserContext";
import { LoginResponse } from "../services/Authenticate";
import ApiService from "../services/Api";
import UserService from "../services/User";

function useAuth() {
	const {
		isAuthenticated,
		isAdmin,
		isSuperAdmin,
		setUser,
	} = useContext(UserContext);

	const onLogin: (r: LoginResponse) => Promise<void> = async ({ accessToken, refreshToken, expiration, }: LoginResponse) => {
		ApiService.setTokens(accessToken, refreshToken, expiration);
		window.localStorage.setItem("refreshToken", refreshToken);
		
		const user = await UserService
			.me();
		setUser(user);
	};

	return {
		isAuthenticated,
		isAdmin,
		isSuperAdmin,
		onLogin,
	};
}

export default useAuth;