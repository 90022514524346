//page to display information about a user such as mail, username, status
//and what role the user has in applications
import { Grid, Typography, Input, Box } from "@mui/material";
import Header from "../components/Header";
import AppMenuInfo from "../components/AppMenuInfo";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import RolesForApp from "../components/RolesForApp";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { AboutUserApp } from "../models/Application";
import UserService from "../services/User";

const AboutUser = () => {

	const [email, setEmail] = useState("");
	const [username, setUsername] = useState("");
	const [status, setStatus] = useState("");
	const [applications, setApplications] = useState<AboutUserApp[]>([]);

	const urlParameters = useParams(); //used to get the id of the user in the url
	const getAppsForUser = async (idUser: string) => {
		const userInfo = await UserService.getUserInfo(idUser);
		setEmail(userInfo.general.email);
		setUsername(userInfo.general.username);
		setStatus(userInfo.general.status);
		setApplications(userInfo.apps);
	};

	useEffect(() => {
		if (urlParameters.id) {
			getAppsForUser(urlParameters.id);
		}
	}, [setEmail, setUsername, setStatus, setApplications]);

	const styles = {
		statusBox: {
			display: "flex",
			alignItems: "center",
			marginBottom: "20px"
		},
		status: {
			display: "flex",
			alignItems: "center",
			marginRight: "10px"
		},
		menu: {
			textAlign: "center",
			borderRight: "1px solid black",
			minHeight: "100%"
		},
		main: {
			paddingLeft: "50px"
		},
		generalInfo: {
			display: "flex",
			flexDirection: "column",
			marginBottom: "40px"
		},
		input: {
			display: "flex",
			marginBottom: "20px"
		},
		labelInfo: {
			marginRight: "20px",
			width: "20%"
		},
		active: {
			color: status === "ACTIVE" ? "green" : "red",
			marginLeft: "5px"
		},
		roleTitle: {
			marginBottom: "25px"
		}
	};


	return (
		<>
			<Header title="Info about the user" rightEdit="true" idUser={urlParameters.id} goBackTo="/dashboard" />
			<Grid container spacing={2}>
				<Grid item xs={2} sx={styles.menu}>
					<AppMenuInfo apps={applications}/>
				</Grid>
				<Grid item xs={10} style={styles.main}>
					<Box sx={styles.generalInfo}>
						<Box sx={styles.input}>
							<Typography sx={styles.labelInfo}>User email</Typography>
							<Input placeholder={email} disabled/>
						</Box>
						<Box sx={styles.input}>
							<Typography sx={styles.labelInfo}>Username</Typography>
							<Input placeholder={username} disabled/>
						</Box>
						<Box sx={styles.statusBox}>
							<Typography sx={styles.labelInfo}>Status</Typography>
							<Typography sx={styles.status}>
								{status} <CircleRoundedIcon sx={styles.active} fontSize="small"/>
							</Typography>
						</Box>
					</Box>
					<Box sx={{height: "30%"}}>
						<Typography variant="h5" sx={styles.roleTitle}>
							Applications available to the user in the environment staging
						</Typography>
						{
							applications.length > 0 ?
								applications.map((application: AboutUserApp) => {
									return <RolesForApp key={application.name}
										name={application.name} logo={application.logo} role={application.role} />;
								})
								: <></>
						}
					</Box>
				</Grid>
			</Grid>
		</>
	);
};

export default AboutUser;