import { ThemeProvider, Button, Box, TextField, Typography, Chip, createTheme } from "@mui/material";
import Header from "../components/Header";
import NewAppRoles from "../components/NewAppRoles";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { SetStateAction, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Role, File } from "../models/NewApp";
import SimpleSnackbar from "../components/SnackBar";
import { useNavigate } from "react-router-dom";
import ApplicationService from "../services/Applications";
import ApiService from "../services/Api";
import { SketchPicker } from "react-color";

const NewApp = () => {

	const styles = {
		main: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		form: {
			width: "80%",
			display: "flex",
			flexDirection: "column", 
			justifyContent: "space-evenly"
		},
		textfield: {
			width: "50%",
			marginTop: "20px"
		},
		role: {
			marginTop: "60px",
			width: "80%",
			marginBottom: "40px"
		},
		upload: {
			padding: "10px",
			border: "1px dashed black",
			width: "90%"
		},
		checkboxes: {
			width: "80%",
			marginTop: "20px"
		},
		submit: {
			marginBottom: "20px"
		},
		colorPicker: {
			marginTop: "20px",
			display: "flex",
			width: "50%",
			justifyContent: "space-between"
		}
	};

	const [roles, setRoles] = useState<Role[]>([]);
	const [appName, setAppName] = useState("");
	const [url, setUrl] = useState("");
	const [files, setFiles] = useState<any[]>([]);
	const [open, setOpen] = useState(false);
	const [success, setSuccess] = useState(false);
	const [primaryColor, setPrimaryColor] = useState<any>({hex : "#22194D"});

	const navigate = useNavigate();

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"image/png": [".png"], 
			"image/jpeg": [".jpg", ".jpeg"] 
		},
		onDrop: acceptedFiles => {
			setFiles(acceptedFiles.map(file => Object.assign(file, {
				preview: URL.createObjectURL(file)
			})));
		},
		multiple: false
	});

	const thumbs = files.map((file: File) => (
		<div key={file.name}>
			<div>
				<img
					src={file.preview}
					style={{ width: "100%" }}
				/>
			</div>
		</div>
	));
	
	const childToParent = (childData: Role[]) => {
		setRoles(childData);
	};

	const handleNameChange = (event: { target: { value: SetStateAction<string>; }; }) => {
		setAppName(event.target.value);
	};

	const handleUrlChange = (event: { target: { value: SetStateAction<string>; }; }) => {
		setUrl(event.target.value);
	};

	const handlePrimaryColorChange = (color: any) => {
		setPrimaryColor(color);
	};

	const handleSubmit = async () => {
		const formData = new FormData();
		formData.append("logo", files[0]);
		formData.append("name", appName);
		formData.append("redirect_uri", url);
		formData.append("color", primaryColor.hex);
		formData.append("roles", JSON.stringify([...roles,{name: "admin", spec: ""}])); //formData accepts strings or blob
		ApplicationService.addNewApplication(formData)
			.then(() => {
				setSuccess(true);
				setOpen(true);
				setTimeout(async () => {
					await ApiService.refreshTokens(window.localStorage.getItem("refreshToken") as string);
					navigate("/applications");
				}, 1000);
			})
			.catch(() => {
				setOpen(true);
			});
	};

	useEffect(() => {
		files.forEach((file: { preview: string; }) => URL.revokeObjectURL(file.preview));
	}, [roles, files]);

	const customTheme = createTheme({
		palette: {
			primary: {
				main: primaryColor && primaryColor.hex ? primaryColor.hex : "#22194D",
				light: "#FFFFFF"
			},
		}
	});

	return (
		<>
			<SimpleSnackbar isOpen={open} success={success} message="New app created successfully"/>
			<Header title="Add new app" rightEdit="false" goBackTo="/applications"/>
			<Box sx={styles.main}>
				<Box sx={styles.form}>
					<Box style={{width: "30%",}}>
						<Box {...getRootProps()} style={styles.upload}>
							<input {...getInputProps()} />
							<Button>
								Drag & drop the logo here (1 file with .png or .jpeg) <AddPhotoAlternateIcon />
							</Button>
							{ thumbs}
						</Box>
					</Box>
					<TextField id="name" label="Application name" variant="outlined" sx={styles.textfield}
						onChange={handleNameChange}/>
					<TextField id="redirect" label="URL of the application" variant="outlined" sx={styles.textfield}
						onChange={handleUrlChange} placeholder="https://newapp.staging.ibisa.network"/>
					<Box sx={styles.colorPicker}>
						<Box sx={{textAlign: "center"}}>
							<SketchPicker color={primaryColor} onChange={handlePrimaryColorChange}/>
							<Typography sx={{marginTop: "10px"}}>Select the primary color</Typography>
						</Box>
						<Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
							<ThemeProvider theme={customTheme}>
								<TextField label="Preview" variant="outlined" />
								<Button variant="contained">Preview</Button>
							</ThemeProvider>
						</Box>
					</Box>
				</Box>
				<Box sx={styles.role}>
					<Typography variant="h5" sx={{marginBottom: "20px"}}>
						Select the roles that will be available in the staging environment
					</Typography>
					<Box sx={{display: "flex"}}>
						<Chip label="Admin" style={{marginRight: "15px", backgroundColor: "#F0EFE9", 
							color: "#0D7B58", fontWeight: "bold"}} />
						<NewAppRoles childToParent={childToParent}/>
					</Box>
				</Box>
				<Button variant="contained" onClick={handleSubmit} sx={styles.submit}>
					Submit
				</Button>
			</Box>
		</>
	);
};

export default NewApp;