//component used when creating a user
//this handles the role choosed for a specific application
import { Card, CardContent, CardMedia, ThemeProvider, Typography, Box, Radio, RadioGroup,
	FormControlLabel, FormControl, FormLabel } from "@mui/material";
import { theme } from "../style/theme";
import { useEffect, useState } from "react";
import ApplicationService from "../services/Applications";
import { RoleForApp } from "../models/Role";

const NewUserRole = ({idApp, name, logo, defaultRole, childToParent}: {
	idApp: string;
	name: string;
	logo?: string;
	defaultRole: string;
	childToParent(name: string, associatedApp: {associatedApp: string}): void;
}) => {

	const styles = {
		logo: {
			maxWidth: "30%",
			borderRadius: "10px",
			margin: "auto"
		},
		main: {
			display: "flex"
		},
		roles: {
			width: "60%",
			height: "30%",
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			padding: "20px"
		},
		card: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			width: "20%",
			margin: "10px",
		}
	};

	const [value, setValue] = useState(defaultRole);
	const [roles, setRoles] = useState<RoleForApp[]>();

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>, associatedApp: {associatedApp: string}) => {
		setValue(e.target.value);
		childToParent(e.target.value, associatedApp);
	};

	const getRolesForApp = async (idApp: string) => {
		const newRoles = await ApplicationService.getRolesForApplication(idApp);
		setRoles(newRoles);
	};
	
	useEffect(() => {
		getRolesForApp(idApp);
	}, [setRoles]);
	
	return (
		<ThemeProvider theme={theme}>
			<Box sx={styles.main} id={name}>
				<Card sx={styles.card}>
					<CardMedia
						component="img"
						image={`${process.env.REACT_APP_AUTHORIZATION_SERVER_URL}/images/${logo}`}
						alt="app logo"
						sx={styles.logo}
					/>
					<CardContent>
						<Typography gutterBottom variant="h6" component="div">
							{name}
						</Typography>
					</CardContent>
				</Card>
				<Box sx={styles.roles}>
					<FormControl>
						<FormLabel id="demo-radio-buttons-group-label">Role</FormLabel>
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							name="radio-buttons-group"
							value={value}
							onChange={(e) => {
								handleChange(e, {associatedApp: name});
							}}
						>
							{
								roles && roles.length > 0 ?
									roles.map((role: RoleForApp) => {
										return <FormControlLabel key={role.name} value={role.name}
											control={<Radio />} label={role.name}/>;
									}) : <></>
							}
							<FormControlLabel value="no access" control={<Radio />} label="No access" />
						</RadioGroup>
					</FormControl>
				</Box>
			</Box>
		</ThemeProvider>
	);
};

export default NewUserRole;