import { AboutUserApp, DashboardUser, IAppMenu } from "./Application";

interface IUser {
	idUser: string;
	email: string;
	username: string;
	status: string;
	role?: Role;
}

export interface UserInPage {
	total: number;
	users: DashboardUser[];
}

export enum Role {
	SuperAdmin = "super admin",
	Admin = "admin",
	User = "user"
}

export interface EditUser {
	email: string;
	username: string;
	status?: string;
	rolesForApps: IAppMenu[];
}

export interface UserInfo {
	apps: AboutUserApp[];
	general: { email: string, username: string, status: string};
}

export default IUser;