//login page
//wait for the credentials of the user and check the given information among the db
//if the information are correct, the user is redirected to the home page of the initial app
//if the user already has a session, he's redirected directly to the redirect_uri
import {
	Typography,
	Container,
	TextField,
	Button,
	Box,
	Grid,
	ThemeProvider,
	Theme
} from "@mui/material";
import { FormEvent, useEffect, useState, FunctionComponent, SetStateAction } from "react";
import verifyCredentials from "../services/VerifyCredentials";
import { checkForSession } from "../services/CheckForSession";
import { useSearchParams } from "react-router-dom";
import { theme, climbsTheme, createPersonnalizedTheme } from "../style/theme";
import ibisa_logo from "../images/IBISA-logo_sticky_white_retina.png";
import climbs_logo from "../images/climbs_logo.png";
import ApiService from "../services/Api";

const Login: FunctionComponent = () => {

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [searchParams] = useSearchParams();
	const [ error, setError ] = useState<string | null>(null);

	const [adaptedTheme, useAdaptedTheme] = useState<Theme>(theme);
	const [logo, setLogo] = useState(ibisa_logo);
	const [primaryColor, setPrimaryColor] = useState("");

	const getTheme = async (redirect_uri: string) => {
		await ApiService.get("/login/theme", {redirect_uri})
			.then((response) => {
				setLogo(response.data.logo);
				setPrimaryColor(response.data.color);
				useAdaptedTheme(createPersonnalizedTheme(response.data.color));
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		const redirect_uri = searchParams.get("redirect_uri");
		switch (redirect_uri) {
		case `${process.env.REACT_APP_CLIMBS_URL}/login`:
			useAdaptedTheme(climbsTheme);
			setLogo(climbs_logo);
			break;
		case `${process.env.REACT_APP_WATCHERS_URL}`:
			useAdaptedTheme(theme);
			break;
		default:
			useAdaptedTheme(createPersonnalizedTheme(primaryColor ||"#21a354"));
			break;
		}
		checkForSession(redirect_uri);
		getTheme(redirect_uri as string);
	}, [primaryColor]);

	const redirect_uri = searchParams.get("redirect_uri");
	const state = searchParams.get("state");
	const scope = searchParams.get("scope");
	const client_id = searchParams.get("client_id");
	

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const result = await verifyCredentials(email, password, scope, redirect_uri, state, client_id);
		switch (result) {
		case 200:
			break;
		case 401:
			setError("Wrong email or password. If you think your account is blocked, contact an administrator");
			break;
		case 403:
			setError("You don't have access to this app");
			break;
		case 404:
			setError("Invalid app");
			break;
		default:
			setError("Credentials are wrong or you don't have access to this app");
			break;
		}
	};

	const styles = {
		main: {
			direction: "column",
			alignItems: "center",
			minHeight: "100vh",
			justify: "center",
		},
		modal: {
			border: "1px solid rgb(212, 212, 212)",
			padding: adaptedTheme === theme ? "10%" : "5%",
			borderRadius: "10px",
		},
		login: {
			display: "flex",
			flexDirection: "column" as const,
			alignItems: "center",
		},
		logo: {
			maxWidth: "100%",
			backgroundColor: adaptedTheme.palette.primary.main,
			borderRadius: "10px",
			marginBottom: "20%"
		},
		form: {
			width: "100%"
		},
		submit: {
			backgroundColor: "#23A455",
		}
	};

	return (
		<ThemeProvider theme={adaptedTheme}>
			<Container>
				<Grid container sx={styles.main}>
					<Grid container style={{ alignItems: "center" }}>
						<Grid item lg={3} md={3}></Grid>
						<Grid item lg={6} md={6} sx={styles.modal}>
							<Box style={styles.login}>
								<img src={`${process.env.REACT_APP_AUTHORIZATION_SERVER_URL}/images/${logo}`}
									alt="Ibisa logo" style={styles.logo} />
								<form style={styles.form} onSubmit={(event) => {
									handleSubmit(event);
								}}>
									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										label="Email address"
										type="email"
										autoComplete="email"
										onChange={(e: { target: { value: SetStateAction<string>; }; }) => {
											setEmail(e.target.value);
										}}
									/>
									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										label="Password"
										type="password"
										onChange={(e: { target: { value: SetStateAction<string>; }; }) => {
											setPassword(e.target.value);
										}}
										sx={{marginBottom: "10%"}}
									/>
									{ error && (
										<Typography color="error" style={{textAlign:"center", marginBottom: "10%"}}>
											{ error }
										</Typography>
									)}
									<Button
										type="submit"
										fullWidth
										variant="contained"
										color="primary"
									>
										Sign in
									</Button>
								</form>
							</Box>
						</Grid>
						<Grid item lg={3} md={3}></Grid>
					</Grid>
				</Grid>
			</Container>
		</ThemeProvider>
	);
};

export default Login;
