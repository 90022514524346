import { useState, createContext, ReactElement, Dispatch } from "react";

import User, { Role } from "../models/User";

interface Context {
  user: User | null;
  isAuthenticated: boolean;
  isSuperAdmin: boolean;
  isAdmin: boolean;
  setUser: Dispatch<React.SetStateAction<User | null>>;
}

const UserContext = createContext({ } as Context);

interface UserContextProviderProps {
  initialUser: User | null;
  children: ReactElement | ReactElement[];
}

function UserContextProvider({ initialUser, children }: UserContextProviderProps) {
	const [user, setUser] = useState<User | null>(initialUser);

	const context: Context = {
		user,
		isAuthenticated: user !== null,
		isSuperAdmin: user?.role === Role.SuperAdmin,
		isAdmin: user?.role === Role.Admin,
		setUser,
	};

	return (
		<UserContext.Provider value={context}>
			{ children }
		</UserContext.Provider>
	);
}

export default UserContext;
export { UserContextProvider };
