import { Grid, Box, Typography, FormGroup, TextField, Card, 
	InputAdornment, IconButton, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { SetStateAction, useEffect, useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Api from "../services/Api";
import SimpleSnackbar from "../components/SnackBar";

const SetUpPassword = () => {

	const styles = {
		box: {
			height:"100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},
		card: {
			width: "80%",
			height: "90%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			backgroundColor: "#F9F9F9"
		},
		main: {
			height: "15%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			marginBottom: "20px",
		},
		input: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			marginTop: "30px"
		},
		label: {
			fontWeight: "bold",
			marginBottom: "10px"
		},
		submit: {
			marginTop: "40px",
			width: "20%"
		},
		error: {
			color: "red",
			textAlign: "center",
			marginTop: "10px"
		},
		rules: {
			textAlign: "center",
			fontWeight: "bold",
			marginTop: "20px"
		}
	};

	const urlParameters = useParams(); 
	const idInit = urlParameters.id;

	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);
	const [error, setError] = useState("");
	const [userEmail, setUserEmail] = useState("");
	const [open, setOpen] = useState(false);
	const [success, setSuccess] = useState(false);
	const [disabled, setDisabled] = useState(false);

	const handleChangePassword = (e: { target: { value: SetStateAction<string>; }; }) => {
		setPassword(e.target.value);
	};

	const handleChangeConfirm = (e: { target: { value: SetStateAction<string>; }; }) => {
		setConfirmPassword(e.target.value);
	};

	const handleVisibility = () => {
		setShowPassword(showPassword===false);
	};

	const handleConfirmVisibility = () => {
		setShowConfirm(showConfirm===false);
	};

	const handleSubmit = () => {
		Api.post("/init-password", {
			idInit,
			password,
			confirmPassword
		}).then((response) => {
			if (response.status === 200) {
				setDisabled(true);
				setOpen(true);
				setSuccess(true);
			} else {
				setError(`Error while creating your password. Please check that passwords are
				the same and match the security requirements`);
			}
		})
			.catch(() => {
				setError(`Error while creating your password. Please check that passwords are
				the same and match the security requirements`);
			});
	};

	const getUserById = async (idInit: string) => {
		Api.get("/init-password", {idInit})
			.then((response) => {
				setUserEmail(response.data);
			})
			.catch(() => {
				setUserEmail("undefined");
			});
	};

	useEffect(() => {
		if (idInit) {
			getUserById(idInit);
		}
	}, []);

	return (
		<>
			<SimpleSnackbar isOpen={open} success={success} 
				message="Password created successfully. You can close this window and access the application"/>
			<Box sx={styles.box}>
				<Card sx={styles.card}>
					<Grid container sx={styles.main}>
						<Grid item xs={1} ></Grid>
						<Grid item xs={10} sx={{textAlign: "center", wordWrap: "break-word"}}>
							<Typography variant="h4" sx={{fontSize: "1.5rem"}}>
								Set up your password for <i>{userEmail}</i>
							</Typography>
						</Grid>
						<Grid item xs={1}></Grid>
					</Grid>
					<Typography sx={{textAlign: "center"}}>
						This password will give you access to all IBISA&apos;s app where admins give you the right to access to.
					</Typography>

					<Grid container spacing={2}>
						<Grid item xs={1}></Grid>
						<Grid item xs={10}>
							<FormGroup sx={styles.input}>
								<Typography sx={styles.label}>Password</Typography>
								<TextField type={showPassword ? "text":"password"}  label="Enter password" variant="outlined"
									onChange={handleChangePassword} disabled={disabled} InputProps={{
										endAdornment: <InputAdornment position="end">
											<IconButton disabled={disabled} onClick={handleVisibility}>
												{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
											</IconButton>
										</InputAdornment>
									}} />
							</FormGroup>
							<FormGroup sx={styles.input}>
								<Typography sx={styles.label}>Confirm password</Typography>
								<TextField type={showConfirm ? "text":"password"}  label="Re enter password" variant="outlined"
									onChange={handleChangeConfirm} disabled={disabled} InputProps={{
										endAdornment: <InputAdornment position="end">
											<IconButton disabled={disabled} onClick={handleConfirmVisibility}>
												{showConfirm ? <VisibilityIcon /> : <VisibilityOffIcon />}
											</IconButton>
										</InputAdornment>
									}} />
							</FormGroup>
							{
								error !== "" ?
									<Typography sx={styles.error}>
										{error}
									</Typography> : <></>
							}
							<Typography sx={styles.rules}>
								Your password must be 8 characters minimum and contain at least 
								one number, one upper case and one special character (!@#$%^&*)
							</Typography>
						</Grid>
						<Grid item xs={1}></Grid>
					</Grid>
					<Button disabled={disabled} type="submit" sx={styles.submit} variant="contained"
						onClick={handleSubmit}>Submit</Button>
				</Card>
			</Box>
		</>
	);
};

export default SetUpPassword;