import { createTheme, colors } from "@mui/material";

const theme = createTheme({
	status: {
		danger: "#e53e3e",
	},
	palette: {
		primary: {
			main: "#21a354",
			light: "#FFFFFF"
		},
		secondary: {
			main: colors.orange[500],
			dark: "#000000",
		},
		neutral: {
			main: colors.grey[500],
			darker: colors.grey[700],
			light: colors.grey[300]
		},
	}
});

const climbsTheme = createTheme({
	palette: {
		primary: {
			main: "#0000D7",
			dark: "#22335B"
		},
		secondary: {
			main: "#ffbe21",
			light: "#FEF5E28F",
			contrastText: "#F6F6F8"
		},
	},
	typography: {
		fontFamily: "'Poppins', sans-serif;",
	},
});

const createPersonnalizedTheme = (mainColor: string) => {
	return createTheme({
		palette: {
			primary: {
				main: mainColor
			}
		}
	});
};

export {
	theme,
	climbsTheme,
	createPersonnalizedTheme
};