import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { useNavigate } from "react-router-dom";
import { Button, Grid, ThemeProvider, Typography } from "@mui/material";
import { theme } from "../style/theme";
import EditIcon from "@mui/icons-material/Edit";
import HeaderType from "../models/Header";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";

const Header = (props: HeaderType) => {

	const styles = {
		main: {
			height: "15%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			backgroundColor: theme.palette.primary.main,
			color: "white",
			marginBottom: "20px",
		},
		goBack: {
			textAlign: "center"
		},
		goBackButton: {
			color: "white",
		},
		createAppButton: {
			color: "black",
			backgroundColor: "white",
			"&:hover" : {
				backgroundColor: "white",
				color: "green",
				fontSize: "0.9em"
			}
		},
		arrow: {
			"&:hover": { fontSize: "2em" }
		},
		title: {
			textAlign: "center"
		},
		edit: {
			display: "flex",
			justifyContent: "center"
		},
		icon: {
			marginRight: "10px"
		}
	};

	const navigate = useNavigate();

	return (
		<ThemeProvider theme={theme}>
			<Grid container spacing={2} sx={styles.main}>
				<Grid item xs={2} sx={styles.goBack}>
					<Button onClick={() => {
						if (props.goBackTo) {
							navigate(props.goBackTo);
						} else {
							navigate(-1);
						}
					}} sx={styles.goBackButton}>
						<ArrowBackRoundedIcon sx={styles.arrow}/>
					</Button>
				</Grid>
				<Grid item xs={8} sx={styles.title}>
					<Typography variant="h4">{props.title}</Typography>
				</Grid>
				<Grid item xs={2} sx={styles.edit}>
					{props.rightEdit === "true" ?
						<Button sx={styles.goBackButton} onClick={() => {
							navigate(`/users/${props.idUser}/edit`);
						}}>
							<EditIcon sx={styles.icon} /> Edit user
						</Button> : 
						(props.rightEdit === "add" ?
							<Button sx={styles.createAppButton} onClick={() => {
								navigate("/applications/new");
							}}>
								<AddToQueueIcon sx={styles.icon}/> Add new app
							</Button> : <></>)}
				</Grid>
			</Grid>
		</ThemeProvider>
	);
};

export default Header;