import { TextField, FormGroup, Typography, CardMedia, Button, Box, Chip, Grid } from "@mui/material";
import { SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../components/Header";
import NewAppRoles from "../components/NewAppRoles";
import { Role } from "../models/NewApp";
import { Application } from "../models/Application";
import { RoleForApp } from "../models/Role";
import DeleteAppDialog from "../components/DeleteAppDialog";
import ApplicationService from "../services/Applications";
import SimpleSnackbar from "../components/SnackBar";

const EditApp = () => {

	const urlParameters = useParams(); // object containing the id of the app
	const [application, setApplication] = useState<Application | null>(null);
	const [name, setName] = useState("");
	const [redirect, setRedirect] = useState("");
	const [roles, setRoles] = useState<RoleForApp[]>();
	const [data, setData] = useState<Role[]>([]);
	const [success, setSuccess] = useState(false);
	const [open, setOpen] = useState(false);

	const navigate = useNavigate();
	const idApp = urlParameters.id;

	const childToParent = (childData: Role[]) => {
		setData(childData);
	};

	const handleNameChange = (e: { target: { value: SetStateAction<string>; }; }) => {
		setName(e.target.value);
	};

	const handleRedirectChange = (e: { target: { value: SetStateAction<string>; }; }) => {
		setRedirect(e.target.value);
	};

	const handleSave = async () => {
		if (idApp) {
			await ApplicationService.editApplication(idApp, {name, redirect, new_roles: data})
				.then(() => {
					setSuccess(true);
					setOpen(true);
					setTimeout(() => {
						navigate("/applications", {replace: true});
					}, 2000);
				})
				.catch(() => {
					setOpen(true);
				});
		}
	};

	const getRolesForApp = async (idApp: string) => {
		const rolesForApp = await ApplicationService.getRolesForApplication(idApp);
		setRoles(rolesForApp);
	};

	const getApplication = async (idApp: string) => {
		const app = await ApplicationService.getApplication(idApp);
		setApplication(app);
		setName(app.name);
		setRedirect(app.redirect_uri);
	};

	useEffect(() => {
		if (idApp) {
			getApplication(idApp);
			getRolesForApp(idApp);
		}
	}, [urlParameters, setApplication, setRoles]);

	const styles = {
		div: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			marginLeft: "20px",
			marginBottom:"20px"
		},
		label: {
			marginRight: "20px",
			width: "20%"
		},
		logo: {
			maxHeight: "30%",
			maxWidth: "30%",
			borderRadius: "10px",
		},
	};

	return (
		<>
			{
				application !== null ?
					<>
						<SimpleSnackbar isOpen={open} success={success} message="Application updated successfully"/>
						<Header title={`Edit the application ${application.name}`} rightEdit="false" goBackTo="/applications"/>
						<Grid container>
							<Grid item xs={10} md={10} lg={10} sm={10}>
								<FormGroup sx={styles.div}>
									<Typography sx={styles.label}>Name</Typography>
									<TextField defaultValue={application.name} onChange={handleNameChange}/>
								</FormGroup>
								<FormGroup sx={styles.div}>
									<Typography sx={styles.label}>Redirect url</Typography>
									<TextField defaultValue={application.redirect_uri} onChange={handleRedirectChange} />
								</FormGroup>
								<FormGroup sx={styles.div}>
									<Typography sx={styles.label}>Available roles</Typography>
									{
										roles && roles.length > 0 ?
											roles.map((role: RoleForApp, index: number) => {
												return <Chip key={index} label={
													role.spec ? role.name + " specific " + role.spec : role.name
												}
												sx={{marginRight: "30px"}}/>;
											}) : <></>
									}
									<NewAppRoles childToParent={childToParent}/>
								</FormGroup>
								<FormGroup sx={styles.div}>
									<Typography sx={styles.label}>Logo</Typography>
									<CardMedia
										component="img"
										alt="logo"
										image={`${process.env.REACT_APP_AUTHORIZATION_SERVER_URL}/images/${application.logo}`}
										sx={styles.logo}
									/>
								</FormGroup>
								<Box sx={{textAlign: "center"}}>
									<Button variant="contained" onClick={handleSave}>
										Save
									</Button>
								</Box>
							</Grid>
							<Grid item xs={2} md={2} lg={2} sm={2}>
								<DeleteAppDialog idApp={urlParameters.id}/>
							</Grid>
						</Grid>
					</>
					:
					<>
					</>
			}
		</>
	);
};

export default EditApp;