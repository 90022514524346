//table displayed in dashboard page to show user's global information
import { Table, TableBody, TableCell, TableContainer, TableHead,
	TableRow, Paper, ThemeProvider, Box, TablePagination, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { theme } from "../style/theme";
import EditIcon from "@mui/icons-material/Edit";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import { useState } from "react";
import Dashboard from "../models/Dashboard";
import { DashboardApplication, DashboardUser } from "../models/Application";

const UserTab = ({changePage, total, realData}: Dashboard) => {

	const navigate = useNavigate();

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const handleClickRow = (idUser: string) => {
		navigate(`/users/${idUser}`);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
		changePage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const styles = {
		table: {
			border: "1px solid rgba(224, 224, 224, 1)"
		},
		tableHeader: {
			backgroundColor: "grey",
		},
		row: {
			"&:hover": { backgroundColor: theme.palette.neutral?.light},
			cursor: "pointer"
		},
		title: {
			color: "white",
			textAlign: "center"
		},
		status: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center"
		},
		edit: {
			"&:hover": { color: "green" }
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<TableContainer component={Paper}>
				<Table aria-label="collapsible table" sx={styles.table}>
					<TableHead>
						<TableRow style={styles.tableHeader}>
							<TableCell sx={styles.title}>Username</TableCell>
							<TableCell sx={styles.title}>Email</TableCell>
							<TableCell sx={styles.title}>Applications</TableCell>
							<TableCell sx={styles.title}>Status</TableCell>
							<TableCell sx={styles.title}>Edit</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							realData
								.map((user: DashboardUser, index:number) => {
									return <TableRow key={index}
										sx={styles.row}>
										<TableCell align="center" onClick={() => handleClickRow(user.idUser)}>
											{user.username}
										</TableCell>
										<TableCell align="center" onClick={() => handleClickRow(user.idUser)}>
											{user.email}
										</TableCell>
										<TableCell align="center" onClick={() => handleClickRow(user.idUser)}>
											{
												user.apps.map((app: DashboardApplication) => {
													return <Typography key={app.name}>{app.name}</Typography>;
												})
											}
										</TableCell>
										<TableCell onClick={() => handleClickRow(user.idUser)}>
											<Box sx={styles.status}>
												{user.status} <CircleRoundedIcon
													sx={{
														color: user.status === "ACTIVE" ? "green" : "red", marginLeft: "10px"
													}} />
											</Box>
										</TableCell>
										<TableCell align="center" onClick={() => {
											navigate(`/users/${user.idUser}/edit`);
										}} ><EditIcon sx={styles.edit} /></TableCell>
									</TableRow>;
								})
						}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				component="div"
				rowsPerPageOptions={[5]}
				count={total}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</ThemeProvider>
	);
};

export default UserTab;