//this dialog opens when an admin tries to delete a user
//this feature should not be used yet
//does deleting a user delete the user from every databases ?
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Box } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import UserService from "../services/User";

export default function DeleteUserDialog({idUser}: {idUser: string | undefined}) {
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	//user will be deleted from authorization server's database
	const handleConfirm = async () => {
		await UserService.deleteUser(idUser as string);
		navigate("/dashboard", {replace: true});
	};

	return (
		<Box>
			<Button disabled sx={{display: "flex", flexDirection: "column", color: "#A52A2A"}}>
				<DeleteForeverIcon fontSize="large" sx={{color: "#A52A2A", opacity: "0.2"}}/>
				Delete user (feature in dev)
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Are you sure you want to delete this user ?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
            This action is not reversible. The user will be deleted from the database.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleConfirm} autoFocus>
            Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}
