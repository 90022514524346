/* eslint-disable no-undef */

//redirects the user to the login page with the right paramaters such as client_id, state, scope...
import Cookies from "universal-cookie";
import { config } from "../config";

const loginAuthorizationServer = (cookie: Cookies) => {
	window.location.href = `
		${process.env.REACT_APP_LOGIN_FRONTEND_URL}/login?
		response_type=code
		&client_id=${config.CLIENT_ID}
		&redirect_uri=${process.env.REACT_APP_LOGIN_FRONTEND_URL}
		&scope=openid+email
		&state=${cookie.get("state")}
		` ;
};

export default loginAuthorizationServer;