//main page
//this page simulates the login page of a random IBISA app
//it redirects the user to the Authorization Server to login
//user will be redirected to welcome page if a session has already been initialized
//we check if the user is authenticated by a get request to the authorization server to a protected route (/authenticated)

import Cookies from "universal-cookie";
import React, { useEffect, useState } from "react";
import Loader from "./components/Loader";
import { Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import ExchangeToken from "./services/ExchangeToken";
import useAuth from "./hooks/auth";
import { fetchSession } from "./services/FetchSession"; 

//function used to get the query parameters in the URL
function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const OpenIDLogin = () => {

	const cookies = new Cookies();
	const navigate = useNavigate();
	const query = useQuery();
	const [state, setState] = useState(query.get("state") || null);
	const { onLogin } = useAuth();

	const useStyles = makeStyles(() => ({
		App : {
			textAlign: "center"
		}, 
		AppHeader : {
			backgroundColor: "#282c34",
			minHeight: "100vh",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			fontSize: "calc(10px + 2vmin)",
			color: "white"
		}
	}));

	const classes = useStyles();

	//on rendering we call the db with credential to check if we already have a session
	//if so, the user is redirect to the welcome page with its tokens
	useEffect(() => {
		const code = query.get("code");
		if (state && code && state===cookies.get("state")) {
			ExchangeToken(code)
				.then((response) => {
					if (response.token) {
						onLogin(response.token);
						navigate("/dashboard", {replace: true});
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			fetchSession(onLogin, cookies, setState);
		}
	}, [query, state, cookies]);

	return (
		<Box className={classes.App}>
			<Box className={classes.AppHeader}>
				<Typography variant="h3">Welcome to SSO for IBISA</Typography>
				<Box style={{display: "block", marginBottom: "20px", marginTop: "20px"}}>
					<Loader/>
				</Box>
				<Typography variant="h4">You will be redirected to login page</Typography>
				<Typography variant="h4">If not, try to reload the page or clear cookies and local storage</Typography>
			</Box>
		</Box>
	);
};

export default OpenIDLogin;